import { format } from "date-fns";
import { MAINTENANCE_MODE } from "./utils_maintenanceMode";
import { envLogger } from "./utils_processing";
import packageJSON from "../../package.json";

/**
 * Setup React ENV variables
 * Resource found here: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
 */

/**
 * Update Endpoint & ENV Variables 6/2 at 8:00 AM
 * Azure ENV Endpoint: https://ala-api.azurewebsites.net
 */
const REACT_APP_ENV_AUTH = {
	development: {
		base: "https://apitest.aladvantage.com/alaservices/v1/",
		user: "x-dev-user",
		password: "J99Hf2i3eY#2pqBj234tD2@H$%",
	},
	// Updated 'production' endpoint & web-service creds as of 6/18/2021 at 8:15 AM.
	production: {
		base: "https://api.aladvantage.com/alaservices/v1/",
		user: "x-prod-user",
		password: "7U*hg%53^D*@bq-d@k8f2L$^fd4j",
	},
	testing: {
		base: "https://apitest.aladvantage.com/alaservices/v1/",
		user: "x-test-user",
		password: "M9hf^%2HHf3^$(sn@Kd23p#hsq",
	},
	local: {
		base: "https://apitest.aladvantage.com/alaservices/v1/",
		user: "x-test-user",
		password: "M9hf^%2HHf3^$(sn@Kd23p#hsq",
	},
};

const REACT_APP_ENV_APP = () => {
	const date = format(new Date(), "YYYY.MM.DD");
	return `ALA Portal - ${packageJSON.version} ${date}`;
};

const REACT_APP_ENV_APP_VERSION = REACT_APP_ENV_APP();

const {
	development: dev,
	production: prod,
	testing: test,
	local, // localhost testing (eg 127.0.0.1)
} = REACT_APP_ENV_AUTH;

/**
 * TO CHANGE CURRENT ENVIRONMENT FOR BUILD:
 *  1. Change 'REACT_APP_CURRENT_ENV_NAME' to 'prod'|'test'  (in this file)
 *  2. Change 'REACT_APP_CURRENT_ENV' to 'prod'|'test' (in this file)
 *  3. Update 'REACT_APP_CURRENT_ENV' (in .env file) to 'prod'|'test'
 */

// current environment: name
const REACT_APP_CURRENT_ENV_NAME = "prod";
// current environment: api base/endpoint(s)
const REACT_APP_CURRENT_ENV = prod;

// determines if current environment is 'dev/development/test/localhost'
const isDevOrTest = (enableLog = false) => {
	const nodeEnv = process.env.NODE_ENV;
	const envOverride = REACT_APP_CURRENT_ENV_NAME;
	// Args: 'custom env', 'nodeEnv', 'maintenanceMode'
	if (enableLog) {
		envLogger(REACT_APP_CURRENT_ENV_NAME, nodeEnv, MAINTENANCE_MODE);
	}

	if (envOverride === "prod" || envOverride === "production") return false;

	return (
		nodeEnv === "development" ||
		nodeEnv === "test" ||
		envOverride === "test" ||
		isLocalhost() // added 8/31/2021 at 8:14 AM
	);
};

// checks if current envionrment is 'localhost' or '127.0.0.1'
const isLocalhost = () => {
	return Boolean(
		window.location.hostname === "localhost" ||
			// [::1] is the IPv6 localhost address.
			window.location.hostname === "[::1]" ||
			// 127.0.0.0/8 are considered localhost for IPv4.
			window.location.hostname.match(
				/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
			)
	);
};

// checks for 'localhost' environment
const isLocal = () => {
	const { hostname } = window.location;

	return hostname === `localhost` || hostname === `127.0.0.1`;
};

/**
 * Determines the current environment's alias name and returns it as a string.
 * @returns {String} - Returns the current environment's name alias (eg. 'test', 'prod', 'dev' or 'local')
 */
const getEnvName = () => {
	if (isLocalhost()) return "local";
	if (isDevOrTest(true)) return "test";
	return "prod";
};

export {
	dev,
	prod,
	test,
	// local env (ie localhost/127.0.1.1)
	local,
};

export {
	REACT_APP_CURRENT_ENV as currentEnv,
	REACT_APP_CURRENT_ENV_NAME as currentEnvName,
};

export { REACT_APP_ENV_APP_VERSION as appVersion };

// checks env
export { isDevOrTest, isLocal, isLocalhost, getEnvName };
